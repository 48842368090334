<template>
    <div id="barchart"></div>
</template>

<script>
export default {
  name: 'barChart',
  mounted() {
    // 基于准备好的dom，初始化echarts实例
    var myChart = this.$echarts.init(document.getElementById("barchart"));
    // 绘制图表
    myChart.setOption(this.options);

    window.addEventListener("resize", () => {
     // 调用 echartsInstance.resize 改变图表的大小
       myChart.resize();
    });
  },
  //使用计算属性进行合并
  computed: {
    options() {
      return Object.assign(this.baseOption);
    },
    
  },
  data() {
    return {
      /* 一些配置项的设置内容 */
      baseOption: {
         title: {
         text: "近四年案件数量",
         left: 'center',
         top:"5%",
         textStyle: {
            color: '#92d2e2' // 这里设置标题的字体颜色
        }
          },
        tooltip: {},
        grid: {
         top:"30%",
         left: '15%',
         right: '15%',
         bottom: '10%',
        },
        xAxis: {
           type: 'category',
           data: ['2021', '2022', '2023', '2024'],
           axisTick: {
            show: false // 不显示刻度线
           },
          axisLine: {
           show: false
          },
          axisLabel: {
            color: '#fff'
        }
  },
  yAxis: {
        type: 'value',
        //name:'件',
        //max:300,
        splitLine:{
        lineStyle:{//让y轴刻度为虚线
            type:'dashed'
        },
            show:true
        },
        axisLine: {
            show: false
        },
        axisLabel: {
            color: '#fff'
        }
  },
  series: [
    {
      data: [
      {
          value: 120,
          itemStyle: {
            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: "rgba(34,144,204,0.4)"
                },
                {
                    offset: 0.5,
                    color: "rgba(34,144,204,0.7)"
                },
                {
                    offset: 1,
                    color: "#186add"
                }
            ]),
            },
        },
        {
          value: 200,
          itemStyle: {
            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: "rgba(158,61,180,0.4)"
                },
                {
                    offset: 0.5,
                    color: "rgba(158,61,180,0.7)"
                },
                {
                    offset: 1,
                    color: "#621ea6"
                }
            ]),
            },
        },
        {
          value: 150,
          itemStyle: {
            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: "rgba(26,90,208,0.4)"
                },
                {
                    offset: 0.5,
                    color: "rgba(26,90,308,0.7)"
                },
                {
                    offset: 1,
                    color: "#162bb3"
                }
            ]),
            },
        },
        {
          value: 80,
          itemStyle: {
            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: "rgba(205,96,133,0.4)"
                },
                {
                    offset: 0.5,
                    color: "rgba(205,96,133,0.7)"
                },
                {
                    offset: 1,
                    color: "#8421a4"
                }
            ]),
            },
        }

      ],
      type: 'pictorialBar',
      symbol:'path://M0,10 L10,10 C5.5, 4 5.5,1 5,0 C4.5,1 4.5, 4 0,10 z',
      symbolOffset: [0, 'left'],
      barWidth: '40px'
            
    }
     ],
      },





    }
  }


}
</script>
<style scoped>
  #barchart {
  height:230px;
  width: 100%;
}

</style>
