<template>
          <div class="zwh_title">数字瀛和专委会</div>
      
          <el-row class="sub_title">
          <el-col style="padding-left:15px" :span="6"><span class="depart">专委会名称</span></el-col>
          <el-col style="padding-left:15px" :span="6"><span class="postion">职位</span></el-col>
          <el-col :span="6"><span class="name">姓名</span></el-col>
          <el-col :span="6"><span class="lawfirm">律所</span></el-col>

           </el-row>
          <div style="height:200px; overflow:hidden;margin-top:-10px;">
            <vue3-seamless-scroll
              hover-stop="true"
              hover="true"
              step="0.5"
            >
              <ul class="datalist">
                <li v-for="(item, index) in currentValue" :key="index" :style="{ background: index % 2 === 1 ? '#08245c' : '#0a2c5a' }">
                  <span class="depart">{{ item.depart }}</span>
                  <span class="postion">{{ item.postion }}</span>
                  <span class="name">{{ item.name }}</span>
                  <span class="lawfirm">{{ item.lawfirm }}</span>
                </li>
              </ul>
            </vue3-seamless-scroll>
          </div>
</template>

<script>
import usersData from '@/assets/data/data_zwh.json'; // 导入JSON数据
var data=usersData;
export default {
  name: "readExcel",
  mounted() {
     this.readFile(data);
     this.updateRead();//轮询更新数据
},
  data() {
    return {
      dataList: [],
      currentIndex: 0,
      currentValue:[],
    };
  },
  methods: {
     //原始数据分组，每9个一组，共4组
     readFile(arr) {
      let i = 0; // 1. 从第0个开始截取
      let result = []; // 2. 定义结果，结果是二维数组
      while (i < arr.length) { // 6. 当索引等于或者大于总长度时，即截取完毕
      // 3. 从原始数组的第一项开始遍历
      result.push(arr.slice(i, i + 6)); // 4. 在原有数据上，一次截取6个用于分堆
      i = i + 6; // 5. 这7条数据截取完，再截取下6条数据，以此类推
      }
      this.dataList=result;//8.取第一组数据，初始化图表
      this.currentValue=this.dataList[0];//8.取第一组数据，初始化图表
   
     },

     //轮询计时更新
    updateRead() {
      setInterval(() => {
      if (this.currentIndex < 66) {
        this.currentValue=this.dataList[this.currentIndex];
        this.currentIndex++;
      } else {
        this.currentIndex = 0; // 循环回到第一个子数组
        }

    }, 3000); // 每3秒取出一个值
      
    }


  }
}
</script>
<style scoped>
.zwh_title{
  height:50px;
  text-align:center;
  color:#92d2e2;
  font-weight:bold;
  font-size:18px;
  line-height:50px;
  font-family:'Microsoft YaHei';
}
.sub_title{
  height:30px;
  display:flex;
  justify-content: center;
  list-style: none;
  overflow: hidden;
  line-height: 30px;
  
}
.sub_title span {
  font-size: 12px;
  margin-left:25px;
  text-align:left;
  font-weight:bold;
}


.datalist {
  color: #fff;
  list-style: none;
  line-height: 30px;
  height: 200px;
  overflow: hidden;
  width:90%;
  position: relative;
  left:-15px;
}

.datalist li{
  display:flex;
  overflow: hidden;
  padding-left:15px;
  padding-right:15px;
  
}

.datalist span {
  font-size: 12px;
}



.depart{
  width:30%;
  color: #fff;
  overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.postion{
  width:25%;
  color: #9b3bb3;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.name{
  width:15%;
  color:#8fd2df;
  overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.lawfirm{
  width:30%;
  color:#6b8532;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}



</style>
