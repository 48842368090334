<template>
    <div id="piechart"></div>
</template>

<script>
export default {
  name: 'pieChart',
  mounted() {
    // 基于准备好的dom，初始化echarts实例
    var myChart = this.$echarts.init(document.getElementById("piechart"));
    // 绘制图表
    myChart.setOption(this.options);

    window.addEventListener("resize", () => {
     // 调用 echartsInstance.resize 改变图表的大小
       myChart.resize();
    });
  },
  //使用计算属性进行合并
  computed: {
    options() {
      return Object.assign(this.baseOption);
    },
  },
  data() {
    return {

      /* 一些配置项的设置内容 */
      baseOption: {
         title: {
         text: "案件类型占比",
         left: 'center',
         top:"3%",
         textStyle: {
            color: '#92d2e2' // 这里设置标题的字体颜色
        }
          },
          legend: {
             bottom: '3%',
             width:'50%',
             textStyle:{
               color:'#fff'
             }
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
          },

        series: [
            {
           name: '案件类型占比',
           type: 'pie',
           radius: [50, 110],
           center: ['50%', '50%'],
           roseType: 'area',
           itemStyle: {
               borderRadius: 4
            },
            label:{
              show:true,
              position:'outside',
              formatter:'{d}%',
              color:'#fff'
            },
            emphasis: {
             itemStyle: {
               shadowBlur: 10,
               shadowOffsetX: 0,
               shadowColor: "rgba(0, 0, 0, 0.5)",
             },
           },
      
        data: [
          { value: 26, name: '刑事'},
          { value: 21, name: '民事' },
          { value: 23, name: '行政' },
          { value: 20, name: '劳动' },
          { value: 28, name: '合同' },
          { value: 26, name: '其他' },
 
        ],
  
    }
  ]
      },





    };
  },

}
</script>
<style scoped>
  #piechart {
  height: 400px;
  width: 100%;
}

</style>
