<template>
    <div id="main" ref="chart"></div>
</template>

<script>
import usersData from '@/assets/data/data_lawyer.json'; // 导入JSON数据

//定义数组数据，34个
 var data =usersData; 

// 对数据进行降序排列
data.sort(function(a, b) {
  return a.value - b.value;
});


export default {
  name: 'lineChart',
  mounted() {

    this.averageData(data);//数据分组
    this.initChart();//加载初始数据
    this.updateChart();//轮询更新数据
    

  },
  data() {
    return {
      chart: null,
      result:[],
      currentIndex: 0,
      currentValue:[]
     }
  },
  methods: {
    //原始数据分组，每9个一组，共4组
    averageData(arr) {
      let i = 0; // 1. 从第0个开始截取
      let result = []; // 2. 定义结果，结果是二维数组
      while (i < arr.length) { // 6. 当索引等于或者大于总长度时，即截取完毕
      // 3. 从原始数组的第一项开始遍历
      result.push(arr.slice(i, i + 9)); // 4. 在原有数据上，一次截取9个用于分堆
      i = i + 9; // 5. 这9条数据截取完，再截取下9条数据，以此类推
      }
      this.result=result.reverse();//7.数据翻转
      this.currentValue=this.result[0];//8.取第一组数据，初始化图表
   
     },
    //初始化图表
    initChart() {
      this.chart = this.$echarts.init(this.$refs.chart);
      //this.chart = this.$echarts.init(document.getElementById("main"));
      this.chart.setOption({
         title: {
         text: "全国律师数量",
         left: 'center',
         top:"3%",
         textStyle: {
            color: '#92d2e2' // 这里设置标题的字体颜色
        }
          },
        tooltip: {},
        grid: {
         left: '15%',
         right: '15%',
         bottom: '5%',
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            show: false // 不显示x轴标签
          },
          splitLine: {     //不显示x网格线
            show: false
          },
         },
        yAxis: {
          type: 'category',
          axisLine: {
                show: false,//Y轴线
            },
          axisTick: {
            show: false // 不显示Y轴刻度线
           },
          axisLabel: {//y轴文字的配置
                textStyle: {
                     color: "#fff",//Y轴内容文字颜色
                },
          },
          data: this.currentValue.map(function(item) {
                 return item.name;
                }),
        },
        series: [
        {
          name: "律师数量(人)",
          type: "bar",
          label: {
                normal: {
                    show: true,
                    position: 'outside', //显示在柱子内部
                    textStyle: { color: '#fff' },
                    formatter: '{c}' //单位
                }
            },
          itemStyle: {
                color: {
                    colorStops:[ //柱子的渐变色
                    {
                        offset: 0,
                        color: 'rgba(24, 69, 191, 1)' // 0% 处的颜色
                    },
                    {
                        offset: 1,
                        color: 'rgba(32, 155, 228, 1)' // 100% 处的颜色
                    }
                    ],
                global: false
                }
            },
            barWidth:22, //柱子的宽度
            // barGap:'10%',//间距
          data: this.currentValue.map(function(item) {
                   return item.value;
                }),
        },
        ],

      });


      window.addEventListener("resize", () => {
     // 调用 echartsInstance.resize 改变图表的大小
     this.chart.resize();

    });


    },

    //轮询计时更新图表
    updateChart() {
      setInterval(() => {
      if (this.currentIndex < 4) {
        this.currentValue=this.result[this.currentIndex];
        this.currentIndex++;
        this.initChart();
      } else {
        this.currentIndex = 0; // 循环回到第一个子数组
        this.initChart();
      }

    }, 3000); // 每3秒取出一个值
      
    }








      
    },


}
</script>
<style scoped>
  #main {
  height:400px;
  width: 100%;
}

</style>
