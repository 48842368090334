<template>
          <div class="title_bar"><span class="sub_title">合作品牌</span></div>
          <div class="bar_line"></div>

          <div class="scroll-container">

            <el-row class="scroll-content" :gutter="20"  :style="{ 'animation-duration': duration + 's' }">
                 <el-col :span="8" v-for="item in items" :key="item"><div class="box"><el-image  :src="item" ></el-image></div></el-col>

           </el-row>

          </div>

          <div class="bar_line"></div>

</template>

<script>
export default {
  name: "brandList",
  mounted() {

  },
  data() {
    return {
      duration: 8, // 滚动时间，单位秒
      items: [
        // 需要滚动的内容
        require('@/assets/pic/brand/01.jpg'),
        require('@/assets/pic/brand/02.jpg'),
        require('@/assets/pic/brand/03.jpg'),
        require('@/assets/pic/brand/04.jpg'),
        require('@/assets/pic/brand/05.jpg'),
        require('@/assets/pic/brand/06.jpg'),
        require('@/assets/pic/brand/07.jpg'),
        require('@/assets/pic/brand/08.jpg'),
        require('@/assets/pic/brand/09.jpg'),
        require('@/assets/pic/brand/10.jpg'),
        require('@/assets/pic/brand/11.jpg'),
        require('@/assets/pic/brand/12.jpg'),
        // ...更多
      ]
    };
  },
  methods: {


  }
}
</script>
<style scoped>
.title_bar{
  text-align: center;
  height:60px;
}
.sub_title{
  height:50px;
  text-align:center;
  color:#92d2e2;
  font-weight:bold;
  font-size:18px;
  line-height:50px;
  font-family:'Microsoft YaHei';
}



.scroll-container {
  margin-top:15px;
  padding-left:20px;
  padding-right:20px;
  overflow: hidden;
  position: relative;
  height: 180px; /* 根据需要设置容器高度 */
}
 
.scroll-content {
  position: absolute;
  width:95%;
  animation-name: scroll;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: 2s; /* 延迟动画开始时间，如果需要 */
}
 
.box{
  margin-bottom:15px;
}



@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

.bar_line{
  position:relative;
    background-image: url('./../assets/images/line.png');
    background-size: cover;
    background-position: center bottom;
    height:4px;
    min-width:368px;
    margin-top:15px;
}



</style>
