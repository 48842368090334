<template>
  <screen></screen>
</template>

<script>
import screen from './components/screenComponent.vue'

export default {
  name: 'App',
  components: {
    screen
  }
}
</script>

<style>
html,
body,
#app{
  width:100%;
  height:100%;
  margin:0;
  padding:0;
  overflow: hidden;
  background-color: #000a3b;
  background-image: url('./assets/images/bg.png');
  position:fixed;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size:100% 100%;
  -moz-background-size:100% 100%;
  min-width:1400px;
}
</style>
