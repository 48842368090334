import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import App from './App.vue';
import * as echarts from 'echarts';
import vue3SeamlessScroll from "vue3-seamless-scroll";
import axios from 'axios';

const app = createApp(App)

app.config.globalProperties.$echarts = echarts
app.config.globalProperties.$axios = axios

app.use(ElementPlus)
app.use(vue3SeamlessScroll)
app.mount("#app");


