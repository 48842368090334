<template>
       <el-row :gutter="20" style="margin-top:25px;width:97%;">
        <el-col :span="4">
             <div class="tongji_box">
              <div class="tongji_box2">
                <el-row :gutter="20" >
                 <el-col class="icon-box" :span="6">
                  <el-image class="icon"  :src="require('@/assets/images/icon.png')"  :fit="fit"></el-image>
                  </el-col>
                  <el-col :span="18">
                    <div class="txt_tilte"><span>今日案件数量</span></div>
                 <div class="txt_sub_tilte">{{dataList.CaseToday}} <span>个</span></div>
                  </el-col>
                </el-row>
                 </div>
              </div>
      </el-col>
      <el-col :span="4" :offset="1">
             <div class="tongji_box">
              <div class="tongji_box2">
                <el-row :gutter="20" >
                 <el-col class="icon-box" :span="6">
                  <el-image class="icon"  :src="require('@/assets/images/icon.png')"  :fit="fit"></el-image>
                  </el-col>
                  <el-col :span="18">
                    <div class="txt_tilte"><span>本月案件数量</span></div>
                 <div class="txt_sub_tilte">{{dataList.CaseMonth}} <span>个</span></div>
                  </el-col>
                </el-row>
                 </div>
              </div>
      </el-col>

      <el-col :span="4" :offset="1">
             <div class="tongji_box">
              <div class="tongji_box2">
                <el-row :gutter="20" >
                 <el-col class="icon-box" :span="6">
                  <el-image class="icon"  :src="require('@/assets/images/icon.png')"  :fit="fit"></el-image>
                  </el-col>
                  <el-col :span="18">
                    <div class="txt_tilte"><span>年度案件数量</span></div>
                 <div class="txt_sub_tilte">{{dataList.CaseYear}}  <span>个</span></div>
                  </el-col>
                </el-row>
                 </div>
              </div>
      </el-col>


      <el-col :span="4" :offset="1">
             <div class="tongji_box">
              <div class="tongji_box2">
                <el-row :gutter="20" >
                 <el-col class="icon-box" :span="6">
                  <el-image class="icon"  :src="require('@/assets/images/icon.png')"  :fit="fit"></el-image>
                  </el-col>
                  <el-col :span="18">
                    <div class="txt_tilte"><span>累计案件数量</span></div>
                 <div class="txt_sub_tilte">{{dataList.CaseTotal}} <span>个</span></div>
                  </el-col>
                </el-row>
                 </div>
              </div>
      </el-col>


      <el-col :span="4" :offset="1">
             <div class="tongji_box">
              <div class="tongji_box2">
                <el-row :gutter="20" >
                 <el-col class="icon-box" :span="6">
                  <el-image class="icon"  :src="require('@/assets/images/icon.png')"  :fit="fit"></el-image>
                  </el-col>
                  <el-col :span="18">
                    <div class="txt_tilte"><span>累计客户数量</span></div>
                 <div class="txt_sub_tilte">{{dataList.ConsumerTotal}} <span>个</span></div>
                  </el-col>
                </el-row>
                 </div>
              </div>
      </el-col>

    </el-row>
</template>

<script>
export default {
  name: "staticNum",
  mounted() {
     this.fetchData();
     this.updateRead();//轮询更新数据
},
  data() {
    return {
      dataList: [],
    };
  },
  methods: {
     async fetchData() {
      try {
        const response = await this.$axios.get('https://klapi.yingle.com/klapi/screen/statistics');
        this.dataList = response.data;
      } catch (error) {
        console.error('接口请求错误:', error);
      }
    },

     //轮询计时更新
    updateRead() {
      setInterval(() => {
        
        this.fetchData()

    }, 5000); // 每3秒取出一个值
      
    }


  }
}
</script>
<style scoped>
.tongji_box{
  position: relative;
    width:180px;
    height:90px;
    border-radius:10px;
    background-color:#122f6b;
    display: block;
    margin:0 auto;
    color:#fff;
    background:  linear-gradient(-45deg, transparent 10px, #122f6b 0) bottom right,
                 linear-gradient(45deg, transparent 10px, #122f6b 0) bottom left,
                 linear-gradient(-135deg, transparent 10px, #122f6b 0) top right,
                 linear-gradient(135deg, transparent 10px, #122f6b 0) top left;
    background-size : 50% 50% ;
    background-repeat : no-repeat ;
  }
  .tongji_box2{
    position: absolute;
    top:1px;
    left:1px;
    width:178px;
    height:88px;
    border-radius:10px;
    background-color:#000a3b;
    display: block;
    margin:0 auto;
    color:#fff;
    background:  linear-gradient(-45deg, transparent 10px, #000a3b 0) bottom right,
                 linear-gradient(45deg, transparent 10px, #000a3b 0) bottom left,
                 linear-gradient(-135deg, transparent 10px, #000a3b 0) top right,
                 linear-gradient(135deg, transparent 10px, #000a3b 0) top left;
    background-size : 50% 50% ;
    background-repeat : no-repeat ;
  }

.icon-box{
  padding:20px;
  margin-left:15px;
  margin-right:-20px;
}

.icon{
  width:50px;
  height:50px;
  display: block;
  margin:0 auto;
}

  .txt_tilte{
    text-align:center;
    padding:20px;
  }
  .txt_tilte span{
    color:#00fcfc;
    font-size:14px;
  }

  .txt_sub_tilte{
    text-align:center;
    font-size:18px;
    color:#fff;
    font-weight:bold;
    margin-top:-10px;
  }

  .txt_sub_tilte span{
    font-size:14px;
    font-weight:bold;
  }




</style>
