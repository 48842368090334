<template>
  <div style="text-align: center;height:110px;"><span class="title">数字瀛和全球生态体系</span></div>
    <el-row :gutter="20" style="margin-left:20px;margin-right:20px;">
      <el-col :span="6" :md="6">
        <div class="grid-content bg-purple content ">
              <lineChart></lineChart>
              <div class="content_cover"></div>
              
        </div>
        <div class="grid-content bg-purple  content" style="margin-top:20px;">
              <pieChart></pieChart>
              <div class="content_cover"></div>

        </div>
      </el-col>
      <el-col :span="12" :md="12">

      <staticNum></staticNum>

        <el-row :gutter="20" >
          <el-col :span="12">
        <div class="grid-content bg-purple content_no_title" style="text-align: center;">
          <div style="padding-top:20px;">
          <el-image  style="height: 250px;"  :src="require('@/assets/images/china_map.png')"  :fit="fit"></el-image>
          <el-row :gutter="20" >
            <el-col :span="12">
              <div class="map_txt_box">
                 <div class="txt_tilte"><span>律所数量</span></div>
                 <div class="txt_sub_tilte">3463 <span>个</span></div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="map_txt_box">
                 <div class="txt_tilte"><span>律师人数</span></div>
                 <div class="txt_sub_tilte">12363 <span>个</span></div>
              </div>
            
            </el-col>
          </el-row>
        </div>
        <div class="content_cover"></div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple content_no_title" style="text-align: center;">
          <div style="padding-top:20px;">
          <el-image  style="height: 250px;"  :src="require('@/assets/images/global_map.png')"  :fit="fit"></el-image>
          <el-row :gutter="20" >
            <el-col :span="24">
              <div class="map_txt_box2">
                 <div class="txt_tilte2">全球覆盖<span>100<sup>+</sup></span>个国家和地区</div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="content_cover"></div>
        </div>
      </el-col>


    </el-row>



    
    <el-row :gutter="20" >
          <el-col :span="12">
        <div class="grid-content bg-purple content">
          <div>
            <brandList></brandList>
        </div>
        <div class="content_cover"></div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple content" style="text-align: center;">
          <div>
            <mediumList></mediumList>
        </div>
        <div class="content_cover"></div>
        </div>
      </el-col>


    </el-row>
      
      </el-col>
      <el-col :span="6" :md="6">
        <div class="grid-content bg-purple content_no_title" >
          <div style="padding-top:20px;">
          <video width="100%" height="220" controls  loop="true" autoplay="true" muted="true">
      <source src="https://fhstatic.oss-cn-hangzhou.aliyuncs.com/screen/2024%E6%95%B0%E5%AD%97%E7%80%9B%E5%92%8C%E5%AE%A3%E4%BC%A0%E7%89%87.mp4" type="video/mp4">
      
          </video>
          
          <div class="content_cover"></div>
        </div>
        </div>
        <div class="grid-content bg-purple  content" style="margin-top:20px;">
          <readExcel></readExcel>
          <div class="content_cover"></div>
        </div>
        <div class="grid-content bg-purple  content" style="margin-top:20px;">
          <barChart></barChart>
          <div class="content_cover"></div>
        </div>
     
      </el-col>
    </el-row>

</template>

<script>
import lineChart from "./lineChart.vue";
import barChart from "./barChart.vue";
import pieChart from "./pieChart.vue";
import readExcel from "./readExcel.vue";
import brandList from "./brandList.vue";
import mediumList from "./mediumList.vue";
import staticNum from "./staticNum.vue";
/* 引入字体CSS */
import '@/assets/css/fonts.css';

export default {
  name: "screenComponent",
  components: {
    lineChart,
    barChart,
    pieChart,
    readExcel,
    brandList,
    mediumList,
    staticNum,
  },

  data() {
    return {
     
    };
  },
};
</script>
<style scoped>
.title{
  font-family: 'MyCustomFont', sans-serif;
  color:#fff;
  font-size:36px;
  line-height:110px;
}
.sub_title{
  height:50px;
  text-align:center;
  color:#92d2e2;
  font-weight:bold;
  font-size:18px;
  line-height:50px;
  font-family:'Microsoft YaHei';
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #041450;
  /* background: #041450;
  border: 1px solid #163879; */
  /* height:400px; */
}


.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.content {
    position:relative;
    background-image: url('./../assets/images/box.png');
    background-size: cover;
    background-position: center top;
    min-width:368px;
  }
  .content_cover {
    position:relative;
    background-image: url('./../assets/images/bottom_line.png');
    background-size: cover;
    background-position: center bottom;
    height:60px;
    min-width:368px;
    margin-top:-30px;
    z-index:10;
  }

  .content_no_title {
    position:relative;
    background-image: url('./../assets/images/none_box.png');
    background-size: cover;
    background-position: center top;
    min-width:368px;
  }


  .map_txt_box{
    width:180px;
    height:90px;
    border-radius:10px;
    background-color:#061858;
    display: block;
    margin:0 auto;
    color:#fff;
    background:  linear-gradient(-45deg, transparent 10px, #061858 0) bottom right,
                 linear-gradient(45deg, transparent 10px, #061858 0) bottom left,
                 linear-gradient(-135deg, transparent 10px, #061858 0) top right,
                 linear-gradient(135deg, transparent 10px, #061858 0) top left;
    background-size : 50% 50% ;
    background-repeat : no-repeat ;
  }




  .txt_tilte{
    text-align:center;
    padding:20px;
  }
  .txt_tilte span{
    color:#92d2e2;
    font-size:18px;
    font-weight:bold;
  }

  .txt_sub_tilte{
    text-align:center;
    font-size:18px;
    color:#fff;
    font-weight:bold;
    margin-top:-10px;
  }

  .txt_sub_tilte span{
    font-size:14px;
    font-weight:bold;
  }


  .map_txt_box2{
    width:280px;
    height:90px;
    border-radius:10px;
    background-color:#061858;
    display: block;
    margin:0 auto;
    color:#fff;
    background:  linear-gradient(-45deg, transparent 10px, #061858 0) bottom right,
                 linear-gradient(45deg, transparent 10px, #061858 0) bottom left,
                 linear-gradient(-135deg, transparent 10px, #061858 0) top right,
                 linear-gradient(135deg, transparent 10px, #061858 0) top left;
    background-size : 50% 50% ;
    background-repeat : no-repeat ;
  }

  .txt_tilte2{
    text-align:center;
    padding:20px;
    color:#92d2e2;
    font-size:18px;
    font-weight:bold;
    line-height:35px;
  }
  .txt_tilte2 span{
    color:#fff;
    font-size:20px;
    font-weight:bold;
  
  }



</style>
